import SysParamConfigDrawer from "./component/sysParamConfigDrawer/index.vue";
// 默认导出
export default {
  // 数据存储对象
  data: () => {
    return {
      // 表单字段
      form: {
        yhmc: null,
        zsxm: null,
        jgbm: null
      },
      // 查询表单
      formSearchList: [
        {
          type: "input",
          label: "所属机构：",
          value: "jgbm"
          //   queryParams: {
          //     sqlId: "8f703a9f-9c12-41ab-84fc-a92f0ac1524a",
          //     dictionaryId: "e3fbd1d0-38c4-4b24-8715-6eea3cbed95a"
          // }
        },
        {
          type: "input",
          label: "URL类型：",
          value: "jgbm"
          // queryParams: {
          //   sqlId: "8f703a9f-9c12-41ab-84fc-a92f0ac1524a",
          //   dictionaryId: "e3fbd1d0-38c4-4b24-8715-6eea3cbed95a"
          // }
        },
        {
          type: "input",
          label: "名称：",
          value: "zsxm"
        }
      ],
      // 表格基础数据
      tableColumns: [
        {
          label: "字典名称",
          prop: "yhmc"
        },
        {
          label: "字典描述",
          prop: "zsmc"
        },
        {
          label: "字典编码",
          prop: "yhxb"
        }
      ],
      // 表头按钮组
      btnList: [
        {
          name: "添加",
          type: "success",
          icon: "document-add"
        },
        {
          name: "修改",
          type: "primary",
          icon: "edit"
        },
        {
          name: "复制",
          type: "primary",
          icon: "copy-document"
        },
        {
          name: "删除",
          type: "danger",
          icon: "delete"
        }
      ],
      // 表格数据
      tableData: [
        {
          yhmc: "jiangshan",
          zsmc: "江山",
          yhxb: "男",
          bmmc: "墨仗科技",
          js: "业务支撑"
        },
        {
          yhmc: "jiangshan",
          zsmc: "江山",
          yhxb: "男",
          bmmc: "墨仗科技",
          js: "业务支撑"
        },
        {
          yhmc: "jiangshan",
          zsmc: "江山",
          yhxb: "男",
          bmmc: "墨仗科技",
          js: "业务支撑"
        },
        {
          yhmc: "jiangshan",
          zsmc: "江山",
          yhxb: "男",
          bmmc: "墨仗科技",
          js: "业务支撑"
        },
        {
          yhmc: "jiangshan",
          zsmc: "江山",
          yhxb: "男",
          bmmc: "墨仗科技",
          js: "业务支撑"
        },
        {
          yhmc: "jiangshan",
          zsmc: "江山",
          yhxb: "男",
          bmmc: "墨仗科技",
          js: "业务支撑"
        },
        {
          yhmc: "jiangshan",
          zsmc: "江山",
          yhxb: "男",
          bmmc: "墨仗科技",
          js: "业务支撑"
        },
        {
          yhmc: "jiangshan",
          zsmc: "江山",
          yhxb: "男",
          bmmc: "墨仗科技",
          js: "业务支撑"
        },
        {
          yhmc: "jiangshan",
          zsmc: "江山",
          yhxb: "男",
          bmmc: "墨仗科技",
          js: "业务支撑"
        },
        {
          yhmc: "jiangshan",
          zsmc: "江山",
          yhxb: "男",
          bmmc: "墨仗科技",
          js: "业务支撑"
        }
      ],
      // 分页
      reqPageData: {
        page: 1, // 页码
        limit: 10 // 每页条数
      },
      total: 0 // 分页总条数
    };
  },
  // 注册组件
  components: {
    SysParamConfigDrawer
  },
  // 模板编译完成
  mounted() {
    // 获取一览表格数据
    this.fun_GetList();
  },
  // 自定义方法
  methods: {
    /**
     * 获取一览表格数据
     * @author 滕威
     */
    fun_GetList() {},
    // /**
    //  * 查看详情事件
    //  * @params e 行数据
    //  * @author 滕威
    //  */
    // fun_LookDetails(e) {
    //   console.log(e);
    //   this.$router.push({ path: "/userProcessSetDetails" });
    // },
    /**
     * 点击按钮事件
     * @params e 按钮name
     * @author 滕威
     */
    fun_click(e) {
      switch (e) {
        case "添加":
          // 打开抽屉
          this.$refs.sysParamConfigDrawer.drawerStatus = true;
          break;
        case "修改":
          // 调用修改方法
          this.fun_Edit();
          break;
        default:
          // 调用删除方法
          this.fun_Del();
      }
    },
    /**
     * 改变每页条数执行的函数
     * @params e 每页条数
     * @author 滕威
     */
    fun_LimitChange(e) {
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 改变页码执行的函数
     * @params e 页码数
     * @author 滕威
     */
    fun_PageChange(e) {
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 勾选事件
     * @params e 选中的数据
     * @author 滕威
     */
    fun_HandleSelectionChange(e) {
      // 将勾选的value值赋给multipleSelection数组
      this.multipleSelection = e;
    }
  }
};
