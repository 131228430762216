// 默认导出
export default {
  // 数据存储对象
  data: () => {
    return {
      // 抽屉状态
      drawerStatus: false,
      // 抽屉确定按钮loading状态
      loading: false,
      // 查询表单字段
      form: {
        roleName: null, // 角色名称
        roleNum: null, // 角色编号
        roleType: null, // 角色类型
        roleRemark: null // 角色描述
      },
      // 详情表单基础数据
      formConfigList: [
        {
          label: "所属机构：",
          value: "roleName",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入角色名称",
            trigger: "blur"
          }
        },
        {
          label: "URL类型：",
          value: "roleType",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入角色类型",
            trigger: "change"
          }
          // queryParams: {
          //   sqlId: "8f703a9f-9c12-41ab-84fc-a92f0ac1524a",
          //   dictionaryId: "e3fbd1d0-38c4-4b24-8715-6eea3cbed95a"
          // }
        },
        {
          label: "名称：",
          value: "roleNum",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入角色编号",
            trigger: "blur"
          }
        },

        {
          label: "设备编号：",
          value: "roleRemark",
          type: "textarea",
          required: true,
          rule: {
            required: true,
            message: "请输入角色描述",
            trigger: "blur"
          }
        },
        {
          label: "URL路径：",
          value: "roleRemark",
          type: "textarea",
          required: true,
          rule: {
            required: true,
            message: "请输入角色描述",
            trigger: "blur"
          }
        }
      ],
      // 后台返回错误对象
      errRules: {}
    };
  },

  // 模板编译完成
  mounted() {
    // 获取一览表格数据
    this.fun_GetDetails();
  },

  // 自定义方法
  methods: {
    /**
     * 获取详情
     * @author 滕威
     */
    fun_GetDetails() {},
    /**
     * 表单提交
     * @author 滕威
     */
    fun_SubmitForm() {
      // 校验
      this.$refs.form.$refs.enInform.validate(valid => {
        if (valid) {
          this.drawerStatus = false;
        } else {
          // 校验不通过，返回false，页面给出前端提示
          return false;
        }
      });
      // 关闭抽屉
    },
    /**
     * 抽屉关闭时执行
     * @author 滕威
     */
    fun_DrawerClose() {
      // 清除表单验证和数据
      this.$refs.form.$refs.enInform.resetFields();
      // 关闭时，清除勾选
      this.$parent.$refs.list.$refs.multipleTable.clearSelection();
    }
  }
};
